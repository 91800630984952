<template>
  <div :class="['circle', 'outer', outerClass]">
    <v-layout :class="['circle', 'inner']" align-center justify-center>
      <span v-if="partnerInitials" class="inner-text">
        {{ partnerInitials }}
      </span>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "CirclePlaceholder",
  props: {
    outerClass: String,
    outerColor: String,
    partnerInitials: String,
  },
};
</script>
<style lang="scss" scoped>
.circle {
  border-radius: 50%;

  &.inner {
    position: relative;
    left: 14%;
    top: 14%;
    z-index: 1;
    width: 72%;
    height: 72%;
  }

  &.outer {
    background: rgba(39, 39, 39, 4.26);
    width: 10rem;
    height: 10rem;

    &.transparentColor {
      background: rgba(39, 39, 39, 0.87);

      .inner-text {
        color: $white;
      }
    }
  }
}

.inner-text {
  font-weight: 500;
  font-size: 2.3rem;
  line-height: 49px;
  text-align: center;
  letter-spacing: 3px;
  color: $white;
}

.primary {
  background: $primary;

  .inner-text {
    color: $white;
  }
}

.dark {
  background: #303030;

  .inner-text {
    color: #535353;
  }
}

.transparentColor {
  background: rgba(39, 39, 39, 0.74);
}
</style>
